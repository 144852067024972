<template>
  <div>
    <div class="top-bg">
      <img src="/image/other/terms-of-use.jpg" alt="" />
    </div>
    <div class="general-faq-warp">
      <h1-title :mb="40"> ArcSoft Terms of Service </h1-title>
      <div class="general-div">
        <p>
          The following sets forth your legal agreement with ArcSoft, Inc. and
          its agents and affiliates (collectively, "ArcSoft"). Your use of any
          ArcSoft service or web site (collectively, "Service") that link to
          these terms is subject to these Terms of Service (the "Terms of
          Service"), which govern your use of the ArcSoft web site at
          <a href="http://www.arcsoft.com">www.arcsoft.com</a> and all
          associated sites linked to
          <a href="http://www.arcsoft.com">www.arcsoft.com</a> by ArcSoft, its
          agents and affiliates, including ArcSoft sites around the world
          (collectively, "Site") and all other services provided to you by
          ArcSoft. BY ACCESSING OR USING THIS SERVICE IN ANY WAY, INCLUDING,
          WITHOUT LIMITATION, USE OF ANY SERVICES, DOWNLOAD OF ANY PRODUCTS,
          SOFTWARE OR INFORMATION, OR MERELY BROWSING THE SITE, YOU AGREE TO AND
          ARE BOUND BY THESE TERMS OF SERVICE.
        </p>
        <p>
          ArcSoft reserves the right, at its sole discretion, to change, modify,
          add or remove portion of these Terms of Service at any time. The
          revised version of Terms of Service will be posted at the Site. It is
          your responsibility to review these Terms of Service periodically for
          changes. After changes are published, if you refuse to accept the
          modified content, please actively stop using the service. Your
          continued use of the Service following the posting of changes will
          mean that you accept and agree to the changes.
        </p>
        <p>
          All references to "us," "our" and "ArcSoft" in these Terms of Service
          are intended to refer to ArcSoft, Inc., its agents and its affiliated
          entities. All references to "you," "your" and "user" in these Terms of
          Service are intended to refer to the user of the Service.
        </p>
        <h2 class="general-h2-a">A. Your Use of The Service</h2>
        <p>
          You agree not to use this Service and any material made available or
          enabled by ArcSoft, you or other users of the Service (collectively
          "Materials") for any purpose that is unlawful or prohibited by these
          Terms of Service. When using the Service and the Materials, you shall
          be subject to any guidelines, rules, or licenses found on this Site.
          Such guidelines, rules, or licenses may contain terms and conditions
          in addition to those in these Terms of Service. ArcSoft has no
          obligation to store any material that you upload, post, transmit or
          otherwise make available through your use of the Service ("Your
          Content"). "User Content" means any material uploaded by you or the
          other users of the Service.
        </p>
        <h2 class="general-h2-a">B. Your Conduct While Using the Service</h2>
        <p>
          You are prohibited from posting on or transmitting through this
          Service any material that: (a) is unlawful, harmful, threatening,
          abusive, tortious, harassing, defamatory, libelous, vulgar, obscene,
          sexually explicit, child-pornographic, lewd, profane, invasive of
          another's sensitive information/ privacy, hateful, racially,
          ethnically, or otherwise objectionable; (b) infringes any intellectual
          property right or other proprietary right of any party; (c) contains
          software viruses, Trojan horses, time bombs, corrupted files, or any
          other similar software or programs that may damage the operation of
          another's computer or property of another; (d) engage in any chain
          letters, contests, junk email, pyramid schemes, spamming, surveys or
          other duplicative or unsolicited messages; (e) advertises or offers to
          sell or buy any goods or services for any business purpose, unless
          specially allowed to do so by ArcSoft; (f) use the Service and the
          Materials such that it will mislead a user of the Service into
          believing that they are interacting directly with ArcSoft, its
          affiliates and agents; (g) disrupt or interfere with the security of,
          or otherwise cause harm to the Service, or any Materials, system
          resources, accounts, passwords, servers, or networks connected to or
          accessible through this Site or any affiliated or linked web sites;
          (h) encourages conduct that would constitute a criminal offense, give
          rise to civil liability, or otherwise violate any applicable local,
          state, national, or international law.
        </p>
        <p>
          ArcSoft, in its sole discretion, may (but has no obligation to)
          monitor or review the Service and User Content at any time. ArcSoft
          shall have the right, in its sole discretion to remove any of Your
          Content for any reason (or no reason), including if it violates the
          Terms of Service or any law. If ArcSoft becomes aware of any possible
          violations by you of any provision of the Terms of Service, ArcSoft
          reserves the right to investigate such violations and in its sole
          discretion remove Your Content from the Service. If, as a result of
          such investigation, ArcSoft believes that criminal activity has
          occurred, ArcSoft reserves the right to refer the matter to, and to
          cooperate with, any and all applicable law enforcement authorities.
          Except to the extent prohibited by applicable law, ArcSoft is entitled
          to retain and/or disclose any information, including Your Content,
          account information, your region, your identity or electronic
          communication, in ArcSoft's possession in connection with your use of
          the Site: (a) to satisfy any law, regulation, or government request;
          (b) to enforce the Terms of Service; (c) to respond to any claims that
          the Your Content violates the Terms of Service or rights of third
          parties; (d) to respond to your requests for customer services; (e) if
          such disclosure is necessary or appropriate to operate ArcSoft; or (f)
          to protect the rights or property of ArcSoft, the Service, its users
          or third parties, including the public at large.
        </p>
        <p>
          We reserve the right to terminate or suspend your access to all or
          part of the Service at any time, without notice, for any reason
          whatsoever. Notwithstanding the foregoing, ArcSoft cannot ensure
          prompt removal of questionable User Content posted on the Site or
          distributed by otherwise using the Service. ACCORDINGLY, ARCSOFT DOES
          NOT ASSUME ANY LIABILITY FOR ANY ACTION OR INACTION BY ARCSOFT WITH
          RESPECT TO USER CONDUCT, COMMUNICATION, OR USER CONTENT ON THIS SITE
          OR WHILE USING THE SERVICE.
        </p>
        <h2 class="general-h2-a">C. Your Use of Software</h2>
        <p>
          The software and accompanying documentation that is made available to
          download from this Site or by otherwise using the Service is the
          copyrighted and/or patented work of ArcSoft, its suppliers and/or
          third parties. Use of the software is governed by the terms of the
          license agreement that accompanies or is included with such software.
          Any third-party software available to you on this Site or by otherwise
          using the Service shall be governed by the software licenses of the
          respective third party company and such agreement will be between you
          and the respective company. Please refer to the applicable license to
          determine the terms on which the software is provided, the license
          granted to you, the warranties provided for such software and all
          other relevant terms. You will not be able to download, install or use
          any software that is accompanied by or includes a license agreement
          unless you agree to the terms of such license agreement. Any copying
          or redistribution of the software is prohibited, including any copying
          or redistribution of the software to any other server or location,
          unless the accompanying license agreement expressly allows otherwise.
          You agree that you will not decompile, reverse engineer, or otherwise
          attempt to discover the source code of the software available on this
          Site or obtained by otherwise using the Service. ArcSoft does not
          guarantee that any third-party software you download from this Site or
          obtained by otherwise using the Service will be free of any
          contaminating or destructive code, such as viruses, worms or Trojan
          horses. ArcSoft does not offer any warranty on any third-party
          software you download from this Site or obtained by otherwise using
          the Service.
        </p>
        <h2 class="general-h2-a">D. Intellectual Property</h2>
        <p>
          The content, information, software, images, audio, video, files,
          trademarks, logos, graphics, text and documents ("ArcSoft Materials")
          offered on this Site, as well as their selection and arrangement, are
          owned, controlled or licensed by or to ArcSoft, and is protected by
          copyright, trademark, trade dress, patent, trade secret, unfair
          competition, and/or other intellectual property laws. Except as
          expressly provided herein, ArcSoft and its suppliers do not grant any
          express or implied rights to use the Service and ArcSoft Materials.
          You agree not to copy, reproduce, republish, frame, download,
          transmit, publicly display, modify, rent, lease, loan, sell, assign,
          distribute, license, sublicense, reverse engineer, create derivative
          works based on the Service or ArcSoft Materials, or otherwise
          exploited the Service or any portion of the Service for any commercial
          purpose, unless expressly authorized by ArcSoft. In addition, you
          agree not to use any data mining, robots, or similar data gathering
          and extraction methods in connection with the Service. You may not
          frame or utilize framing techniques to enclose any trademark, logo, or
          other proprietary information (including images, text, page layout, or
          form) of ArcSoft or any third party with a presence on the Site or
          through the Service without our express written consent. You may not
          use any meta tags or any other "hidden text" utilizing ArcSoft's name
          or trademarks without our express written consent.
        </p>
        <h2 class="general-h2-a">E. License to User Content</h2>
        <p>
          By posting, uploading, inputting, providing or submitting ("Posting")
          Your Content, you are granting ArcSoft, its affiliates and
          sublicensees permission to use Your Content in connection with
          providing the Service, including, without limitation, the license
          rights to: copy, distribute, transmit, publicly display, publicly
          perform, reproduce, edit, translate and reformat Your Content and the
          right to sublicense such rights to any supplier of the Service.
        </p>
        <p>
          No compensation will be paid with respect to the use of Your Content,
          as provided herein. ArcSoft is under no obligation to post or use any
          of Your Content you may provide and ArcSoft may remove any of Your
          Content at any time in its sole discretion.
        </p>
        <p>
          By Posting Your Content you warrant and represent that you own or
          otherwise control all of the rights to Your Content as described in
          these Terms of Service including, without limitation, all the rights
          necessary for you to provide, post, upload, input or submit Your
          Content. In addition, by Posting Your Content that contain image,
          photographs, pictures or that are otherwise graphical in whole or in
          part ("Images"), you warrant and represent that (a) you are the
          copyright owner of such Images, or that the copyright owner of such
          Images has granted you permission to use such Images, (b) you have the
          rights necessary to grant the licenses and sublicenses described
          above, and (c) that each person depicted in such Images, if any, has
          provided consent to the use of the Images as set forth in these Terms
          of Service, including, without limitation, the distribution, public
          display and reproduction of such Images. By Posting Images, you are
          granting (a) to all users of the Service, and/or (b) to the general
          public, permission to use your Images in connection with the use of
          the Service, as permitted by these Terms of Service, and including,
          without limitation, a non-exclusive, world-wide, royalty-free license
          to: copy, distribute, transmit, publicly display, publicly perform,
          reproduce, edit, translate and reformat your Images without having
          your name attached to such Images, and the right to sublicense such
          rights to any supplier of the Service. The licenses granted in the
          preceding sentences for Images will terminate at the time you
          completely remove such Images from the Service, provided that, such
          termination shall not affect any licenses granted in connection with
          such Images prior to the time you completely remove such Images. No
          compensation will be paid with respect to the use of your Images.
        </p>
        <p>
          If it is determined that you retain moral rights (including rights of
          attribution or integrity) in Your Content, you agree that (a) you do
          not require any personally identifying information be used in
          connection with Your Content, or any derivative works thereto; (b) you
          have no objection to the publication, use, modification, deletion and
          exploitation of Your Content by ArcSoft or its licensees, successors
          and assigns; (c) you forever waive and agree not to claim or assert
          any entitlement to any and all moral rights of an author in any of
          Your Content; and (d) you forever release ArcSoft, and its licensees,
          successors and assigns, from any claims that you could otherwise
          assert against ArcSoft by virtue of any such moral rights.
        </p>
        <h2 class="general-h2-a">F. Copyrights</h2>
        <p>
          ArcSoft respects the Intellectual Property Rights of others. ArcSoft
          will respond to clear notices of copyright infringement consistent
          with the Digital Millennium Copyright Act, and its response to such
          notices may include removing or disabling access to the allegedly
          infringing content, terminating the accounts of repeat infringers,
          and/or making good-faith attempts to contact the user who posted the
          content at issue so that he may, where appropriate, make a
          counter-notification. If you believe that your work has been used or
          copied in a way that constitute copyright infringement, and such
          infringement is posted on the Site or by otherwise using the Service,
          please contact us by regular mail, fax or email at the following
          address and provide all relevant details.<br />
          Legal Counsel<br />
          ArcSoft, Inc.<br />
          46657 Fremont Boulevard<br />
          Fremont, CA 94538<br />
          Phone number: 510-440-9901<br />
          Fax number: 510-440-1270<br />
        </p>
        <h2 class="general-h2-a">G. Usage by Children</h2>
        <p>
          The Service is not intended for or directed to persons under the age
          of 13. ArcSoft cannot prohibit minors from using the Service. ArcSoft
          must rely on parents, guardians and those responsible for supervising
          children under the age of 13 to decide which materials are appropriate
          for such children to view and/or purchase.
        </p>
        <p>
          We require that use of the Service and all purchases made through it
          be by individuals who are 13 years of age or older. ArcSoft does not
          permit registration by and will not knowingly collect personally
          identifiable information from anyone under 13, nor does ArcSoft sell
          software products for purchase by children under the age of 13. EACH
          TIME YOU PURCHASE A PRODUCT, REGISTER WITH THIS SITE OR OTHERWISE USE
          THE SERVICE OR PROVIDE INFORMATION TO ARCSOFT, YOU ARE REPRESENTING TO
          ARCSOFT THAT YOU ARE 13 YEARS OF AGE OR OLDER.
        </p>
        <h2 class="general-h2-a">H. Accounts, Passwords and Security</h2>
        <p>
          You agree not to use or attempt to use another's account or password
          without authorization from ArcSoft. You agree not to share your
          password with others. You are entirely responsible for maintaining the
          confidentiality of your password and account and are fully responsible
          for all activities that occur under such password or account. You
          agree to (a) immediately notify ArcSoft of any unauthorized use of
          your password or account or any other breach of security, and (b)
          ensure that you exit from your account at the end of each session.
          ArcSoft will not be liable for any loss or damage that you may incur
          as a result of someone else using your password or account, either
          with or without your knowledge. However, you could be held liable for
          losses incurred by ArcSoft or another party due to someone else using
          your account or password.
        </p>
        <h2 class="general-h2-a">I. Contests and Promotions</h2>
        <p>
          ArcSoft may conduct contests and promotions on this Site or otherwise
          made available through the Service from time to time. Each such
          contest or promotion will have additional terms, conditions and rules
          ("Rules") which will be posted or otherwise made available to you and,
          for purposes of each contest or promotion, the Rules will be deemed
          incorporated into and form a part of the Terms of Service. If any
          conflict arises between the Terms of Service and the Rules, the Rules
          shall govern for purposes of your participation or involvement in the
          promotion or contest to which the Rules relate. Any personal
          information you submit to ArcSoft for contests or promotions shall be
          subject to the terms of ArcSoft's Privacy Policy
          (http://www.arcsoft.com/company/privacy_policy.html). All material
          submitted by you or other users of the Service, in connection with the
          contests or promotions, including but not limited to images, artwork,
          graphics, photographs, text, audio and videos, are User Content that
          is governed by the provision under section E of these Terms of
          Service. ArcSoft reserves the right to not accept any User Content
          submitted for the purpose of entering the contests or promotions after
          receiving notice of copyright infringement on such work and the
          copyright ownership of such work cannot be confirmed.
        </p>
        <h2 class="general-h2-a">J. DISCLAIMERS</h2>
        <p>
          THE SERVICE, INCLUDING THIS SITE AND ALL PRODUCTS AND SERVICES
          PROVIDED BY ARCSOFT, ARE PROVIDED "AS IS" AND ARCSOFT EXPRESSLY
          DISCLAIMS ALL EXPRESS, IMPLIED, AND STATUTORY WARRANTIES, INCLUDING,
          WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WITHOUT
          LIMITING THE FOREGOING, ARCSOFT MAKES NO WARRANTY THAT (a) THIS SITE,
          THE SERVICES OR THE PRODUCTS WILL MEET YOUR REQUIREMENTS; (b) THIS
          SITE, THE SERVICES OR THE PRODUCTS WILL BE EFFECTIVE, ACCURATE,
          UNINTERRUPTED, RELIABLE, ERROR-FREE OR SECURE; (c) THE QUALITY OF ANY
          PRODUCTS, SERVICES OR MATERIALS PURCHASED OR ACCESSIBLE BY YOU THROUGH
          THIS SITE OR BY OTHERWISE USING THE SERVICE WILL MEET YOUR
          EXPECTATIONS; (d) ANY ERRORS IN THE SOFTWARE OBTAINED FROM OR USED
          THROUGH THE SERVICE, OR ANY DEFECTS IN THE SERVICE WILL BE CORRECTED;
          (e) ANY FILES OR OTHER DATA YOU DOWNLOAD FROM THIS SITE OR OTHERWISE
          OBTAINED BY USING THE SERVICE WILL BE FREE OF COMPUTER VIRUSES OR
          CONTAMINATION OR DESTRUCTIVE FEATURE AND WILL NOT CAUSE DAMAGE TO YOUR
          COMPUTER SYSTEM, LOSS OF DATA, OR OTHER HARM; AND (f) THE SERVICE DOES
          NOT INCLUDE TECHNICAL OR OTHER MISTAKES, INACCURACIES, OR
          TYPOGRAPHICAL ERRORS.
        </p>
        <p>
          ARCSOFT SPECIFICALLY DISCLAIMS ANY LIABILITY WITH REGARD TO ANY
          ACTIONS RESULTING FROM YOUR USE OF THE SERVICE. ANY MATERIAL
          DOWNLOADED OR OTHERWISE OBTAINED THROUGH USE OF THE SERVICE IS
          ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY
          RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA
          THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
        </p>
        <p>
          YOU UNDERSTAND AND ACKNOWLEDGE THAT (a) ARCSOFT DOES NOT CONTROL,
          ENDORSE, OR ACCEPT RESPONSIBILITY FOR ANY CONTENT, PRODUCTS OR
          SERVICES OFFERED BY THIRD PARTIES THROUGH THE SERVICE, INCLUDING,
          WITHOUT LIMITATION, THIRD-PARTY VENDORS AND THIRD PARTIES ACCESSIBLE
          THROUGH LINKS ON THIS SITE; (b) ARCSOFT MAKES NO REPRESNTATIONS OR
          WARRANTIES WHATSOEVER ABOUT, AND SHALL NOT BE HELD LIABLE FOR ANY
          DAMAGES, INCLUDING DIRECT, SPECIAL, INDIRECT, INCIDENTAL OR
          CONSEQUENTIAL LOST REVENUES OR LOST PROFITS THAT MAY RESULT FROM THE
          USE OF THIRD PARTY MATERIALS, CONTENT, PRODUCTS OR SERVICES; AND (c)
          ANY DEALINGS YOU MAY HAVE WITH SUCH THIRD PARTIES ARE AT YOUR OWN
          RISK.
        </p>
        <p>
          MANAGERS, HOSTS, PARTICIPANTS, MODERATORS AND OTHER THIRD PARTIES ARE
          NOT AUTHORIZED ARCSOFT SPOKEPERSONS, AND THEIR VIEWS DO NOT
          NECESSARILY REFLECT THOSE OF ARCSOFT. TO THE MAXIMUM EXTENT PERMITTED
          BY APPLICABLE LAW, ARCSOFT WILL HAVE NO LIABILITY RELATED TO USER
          CONTENT ARISING UNDER INTELLECTUAL PROPERTY RIGHTS, LIBEL, PRIVACY,
          PUBLICITY, OBSCENITY OR OTHER LAWS. ARCSOFT ALSO DISCLAIMS ALL
          LIABILITY WITH RESPECT TO THE MISUSE, LOSS, MODIFICATION OR
          UNAVAILABILITY OF ANY USER CONTENT.
        </p>
        <h2 class="general-h2-a">K. LIMITATION OF LIABILITY</h2>
        <p>
          IN NO EVENT WILL ARCSOFT, ITS SUBSIDIARIES, AFFILIATES OR ANY OF THEIR
          RESPECTIVE EMPLOYEES OR AGENTS BE LIABLE FOR ANY DAMAGES INCLUDING,
          WITHOUT LIMITATION, DIRECT, INCIDENTAL, PUNITIVE, SPECIAL OR
          CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF USE, DATA, BUSINESS OR
          PROFITS) ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF OR INABILITY
          TO USE THE SERVICE, WHETHER OR NOT FORESEEABLE OR IF ARCSOFT HAS BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR BASED ON ANY THEORY OF
          LIABILITY, INCLUDING BREACH OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS
          ACTION, EVEN IF AN AUTHORIZED REPRESENTATIVE OF ARCSOFT HAS BEEN
          ADVISED OF OR SHOULD HAVE KNOWLEDGE OF THE POSSIBILITY OF SUCH
          DAMAGES. ARCSOFT'S AGGREGATE LIABILITY, AND THAT OF ITS SUBSIDIARIES,
          EMPLOYEES, SUPPLIERS AND DISTRIBUTORS UNDER OR IN CONNECTION WITH
          THESE TERMS OF SERVICE SHALL BE LIMITED TO THE GREATER OF (A) THE
          AMOUNT OF FEES YOU PAY TO US IN THE 12 MONTHS PRIOR TO THE ACTION
          GIVING RISE TO LIABILITY, OR (B) ONE HUNDERED UNITED STATES DOLLARS
          ($100). THE FOREGOING DISCLAIMERS, WAIVERS AND LIMITATIONS SHALL APPLY
          NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED
          REMEDY.
        </p>
        <h2 class="general-h2-a">L. Privacy</h2>
        <p>
          Please review our Privacy Policy
          (http://www.arcsoft.com/company/privacy_policy.html) to understand our
          practices with regard to any images, data or other information you
          submit to the Site or by otherwise using the Service. You hereby agree
          that the Privacy Policy governs your use of the Service and is
          incorporated in these Terms of Service. Additionally, by using the
          Service, you understand that any message or information you send by
          using the Service may be read or intercepted by others, even if there
          is a special notice that a particular transmission (for example,
          credit card information) is encrypted.
        </p>
        <h2 class="general-h2-a">M. Indemnification</h2>
        <p>
          You agree to indemnify, hold harmless and defend ArcSoft, its
          officers, directors, employees, agents, successors, subsidiaries,
          affiliates, sublicensees from and against all claims, damages, losses,
          costs and liabilities (including reasonable attorneys' fees and court
          costs) arising out of or related to any breach of these Terms of
          Service or violation of any law or the rights of a third party, by you
          or anyone acting under your direction, authority or control, including
          without limitation the publication, distribution, exhibit or other use
          of the content posted by you or by anyone using your password and/or
          your account.
        </p>
        <h2 class="general-h2-a">N. Changes to This Site</h2>
        <p>
          ArcSoft may make changes to any products or services on this Site or
          to the applicable prices for any such products or services, at any
          time, without notice. The materials on this Site with respect to
          products and services may be out of date, and ArcSoft makes no
          commitment to update the materials on this Site with respect to such
          products and services.
        </p>
        <h2 class="general-h2-a">O. International Use</h2>
        <p>
          Recognizing the global nature of the Internet, you agree to comply
          with all local rules regarding online conduct and acceptable content.
          Please note that information published on this Site or otherwise made
          available to you by the Service may refer to products, programs or
          services that are not available in your country. Contact ArcSoft
          Support (<a href="http://www.arcsoft.com/support/"
            >http://www.arcsoft.com/support</a
          >) for information regarding the products, programs and services that
          may be available to you.
        </p>
        <p>
          The Service is controlled, operated, and administered by ArcSoft from
          its offices within the United States of America. ArcSoft makes no
          representation that the Service is appropriate or available for use at
          other locations outside the United States, and access to the Service
          from territories where the Service is illegal is prohibited. If you
          access the Service from a location outside the United States, you are
          responsible for compliance with all local laws where you reside. See
          the section on export control laws, below, for further information.
        </p>
        <h2 class="general-h2-a">P. Export Controls</h2>
        <p>
          Certain ArcSoft products, including software, documentation, services,
          and related technical data, available via the Service may be subject
          to export controls administered by the United States (including, but
          not limited to, the U.S. Department of Commerce Export Administration
          Regulations) and other countries, including, controls for re-export
          under European Union, the Singapore Strategic Goods Control Act, and
          the import regulations of other countries. Export, re-export or import
          of products and services may require action on your behalf prior to
          purchase and it is your responsibility to comply with all applicable
          international, national, state, regional and local laws and
          regulations, including any export, import and use restrictions. You
          agree not to download any content, services or software from ArcSoft
          or otherwise export or re-export into Cuba, Iraq, Libya, North Korea,
          Iran, Syria or to any country then subject to U.S. trade sanctions,
          and/or to any person or entity named on the U.S. Department of
          Commerce Denied Persons, Entities and Unverified Lists, the U.S.
          Department of State's Debarred List, or the U.S. Department of
          Treasury's lists of Specially Designated Nationals, Specially
          Designated Narcotics Traffickers, or Specially Designated Terrorists.
          By downloading or using any content, services or software from
          ArcSoft, you are acknowledging that you have read and understood this
          provision and agree to comply with all applicable export control laws
          and you are representing that you are not located in, under the
          control of, or a national or resident of any prohibited country and
          are not a prohibited person or entity.
        </p>
        <h2 class="general-h2-a">Q. Government Restricted Rights</h2>
        <p>
          The software available on this Site and accompanying documentation
          that is downloaded from this Site for or on behalf of the U.S.
          Government, its agencies and/or instrumentalities are provided with
          Restricted Rights. You agree to meet all requirements necessary to
          ensure that the U.S. Government will honor such rights. Disclosure,
          use or reproduction of the software and accompanying documentation are
          subject to restrictions set forth at Federal Acquisition Regulation
          12.211 and 12.212 (Acquisition of Commercial Items). Use of the
          materials by the U.S. Government constitutes acknowledgment of
          ArcSoft's proprietary rights in them.
        </p>
        <h2 class="general-h2-a">R. Disputes</h2>
        <p>
          You agree that any dispute relating in any way to your use of the
          Service shall be resolved exclusively by binding arbitration in Santa
          Clara County, California, except that, to the extent you have in any
          manner violated or threatened to violate ArcSoft's intellectual
          property rights, ArcSoft may seek injunctive or other appropriate
          relief in any state or federal court in Santa Clara County in the
          State of California, and you consent to exclusive jurisdiction and
          venue in such courts. Arbitration under these Terms of Service shall
          be conducted under the rules then prevailing of the American
          Arbitration Association. The arbitrator's award shall be binding and
          may be entered as a judgment in any court of competent jurisdiction.
          To the fullest extent permitted by applicable law, no arbitration
          under these Terms of Service shall be joined to an arbitration
          involving any other party subject to these Terms of Service, whether
          through class arbitration proceedings or otherwise. Unless otherwise
          expressly required by applicable law, each party is responsible for
          its own attorneys' fees without regard to which party is deemed the
          prevailing party in the arbitration proceeding. If this arbitration
          provision is held to be invalid or otherwise unenforceable for any
          reason, it will be severed from these Terms of Service and the parties
          agree that exclusive jurisdiction and venue for any claims will be in
          state or federal courts in Santa Clara County in the State of
          California.
        </p>
        <h2 class="general-h2-a">S. General Terms</h2>
        <p>
          All legal issues arising from or related to the use of the Service
          shall be construed in accordance with the laws of the State of
          California without regard to conflicts of law principles. These Terms
          of Service constitute the entire agreement between you and ArcSoft
          with respect to your use of the Service and supersede any prior
          agreement between you and ArcSoft on such subject matter. ArcSoft's
          failure to enforce any right or provision in these Terms of Service
          shall not constitute a waiver of such right or provision or of any
          other rights or provisions in these Terms of Service. If for any
          reason a court of competent jurisdiction finds any provision of the
          Terms of Service, or portion thereof, to be unenforceable, that
          provision shall be enforced to the maximum extent permissible so as to
          reflect the intent of the parties for that provision, and the
          remainder of the Terms of Service shall continue in full force and
          effect. You may not assign or otherwise transfer the Terms of Service,
          or any right granted hereunder, without ArcSoft's written consent.
          ArcSoft's rights under the Terms of Service are freely transferable by
          ArcSoft.
        </p>
        <p>
          <em>Last updated: September 1, 2024</em>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TermsOfUse',
  metaInfo: {
    title: 'ArcSoft Terms of Service',
    meta: [
      {
        name: 'description',
        content:
          "Review ArcSoft's Terms of Service to understand the terms and conditions that govern the use of our products and services.",
      },
      {
        name: 'keywords',
        content:
          'Terms of Service, user agreement, service terms, usage policies, ArcSoft terms, legal conditions, user obligations, service limitations, terms and conditions, legal agreements', // 关键词
      },
    ],
  },
};
</script>
<style lang="less" scoped>
.general-faq-warp {
  width: 960px;
  margin: 0 auto;
  padding: 80px 0;
}

.general-h2-a {
  margin-bottom: 8px;
}

p {
  margin-bottom: 40px;
}

.top-bg {
  width: 100%;
  height: 100%;
  img {
    display: block;
    width: 100%;
  }
}
</style>
